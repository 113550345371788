body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

button {
    font-weight: bold;
    font-size: 1rem;
    padding: 4px 8px;
    border-radius: 8px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease 0s;
    color: rgba(120,181,176,255);
    background-color: rgba(46,107,102,0.5);
    border: 3px solid rgba(120,181,176,255);
    font-family: Barlow;
}

#container {
    font-size: 16px;
    display: flex;
    font-family: Barlow;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#mobileContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100vh;
    width: 100vw;
}

#imageWrapper {
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
}

#logo {
    margin-bottom: auto;
    height: 20vh;
}

.rightContainer{
    display: flex;
    flex-direction: column;
}

.connectWallet {
    text-align: center;
    min-height: 10vh;
    display: flex;
    margin-bottom: auto;
    height: 20vh;
    margin-right: 20px;
}

.walletButton {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 250px;
    height: 100px;
    font-size: 2rem;
    color: white;
}

.mobileConnectWallet{
    display: flex;
    margin-bottom: 2vh;
}

.mobileWalletButton{
    margin: auto;
    width: 50vw;
    height: 10vh;
    font-size: 1.5rem;
    border-radius: 50px;
}

.mintContainer{
    margin: auto;
}

.mintInfo {
    width: 650px;
    height: 650px;
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    justify-content: space-evenly;
}

.mintStatus {
    line-height: 1.2;
    font-size: 32px;
    color: white;
    font-style: normal;
    font-weight: bold;
}

.mintHeader {
    line-height: 1.2;
    font-size: 24px;
    color: white;
}

.mintButtons {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    min-height: 225px;
}

.mintBtn {
    margin-top: 10px;
}

.mintCount {
    font-size: 2rem;
    padding: 0px 8px;
}

#inputContainer {
    display: flex;
    height: 100%;
}

.input {
    text-align: center;
    width: 30vw;
    max-width: 300px;
    border-top: 4px solid rgba(120,181,176,255);
    border-bottom: 4px solid rgba(120,181,176,255);
    background-color: rgba(255,255,255,0.5);
    display: flex;
}

.inputText {
    margin: auto;
    font-size: 2rem;
}

.increment {
    cursor: pointer;
    transition: transform 0.2s ease 0s;
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.incrementContainer {
    cursor: pointer;
    height: 50px;
    width: 50px;
    background-color: rgba(46,107,102,0.5);
    border: 4px solid rgba(120,181,176,255);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.incrementContainer:first-child{
    border-radius: 5px 0px 0px 5px;
}

.increment:hover {
    transform: scale(0.8);
}


